<template>
  <div class="row mx-md-0">
    <div class="col-12 p-md-3 py-3 px-0" :class="{'lightgrey_bg': isOdd}">
      <!--DESKTOP-->
      <div class="row align-items-center d-none d-md-flex position-relative">
        <div class="col-md-3 p-0 font15 bold">
          <div class="row m-0 align-items-center py-1" :class="{'round': item.organisationID}">
            <div class="col-auto">
              <IconOrganisations size="size12" v-if="item.organisationID" />
              <IconGroups size="size12" v-else-if="item.donorGroupID"/>
              <IconSingleDonor size="size12" v-else />
            </div>
            <div class="col text-truncate">
              <span v-if="item.organisationID">Org: "{{theOrganisation}}"</span>
              <span v-else-if="item.donorGroupID"> Group: "{{theGroup}}"</span>
              <span v-else>
                <span v-if="item.donationMadeBy">{{item.donationMadeBy.firstName}} {{item.donationMadeBy.lastName}}</span>
                <span v-else>{{item.user.firstName}} {{item.user.lastName}}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-2 p-0 text-center font15">
          {{item.transactionReference}}
        </div>
        <div class="col-md-2 p-0 text-center font15">
          {{date}}
        </div>
        <div class="col-md-1 p-0 text-center font15 bold">
          {{ defaultCurrency }} {{ parseFloat(Math.round(item.estimatedValue * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-md-2 p-0 text-center bold font15">
          {{item.paymentOption.method}}
        </div>
        <div class="col-md-2 p-0 text-end">
          <div class="row align-items-center justify-content-center">
            <div class="col-auto px-1">
              <Button color="green" btnText="VIEW" size="small" @click="view"/>
            </div>
          </div>
        </div>
      </div>
      <!--END DESKTOP-->
      <!--MOBILE-->
      <div class="row m-0 align-items-center d-md-none position-relative font13">
        <div class="col-3 p-0 text-center">
          {{item.transactionReference}}
        </div>
        <div class="col-3 p-0 text-center">
          {{date}}
        </div>
        <div class="col-3 p-0 text-center bold">
          {{ defaultCurrency }} {{ parseFloat(Math.round(item.estimatedValue * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-3 p-0 text-center">
          <Button color="green" btnText="VIEW" size="small" @click="view"/>
        </div>
      </div>
      <!--END MOBILE-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    IconSingleDonor: defineAsyncComponent(() => import('../components/icons/IconSingleDonor.vue')),
    IconOrganisations: defineAsyncComponent(() => import('../components/icons/IconOrganisations.vue')),
    IconGroups: defineAsyncComponent(() => import('../components/icons/IconGroups.vue'))
  },
  name: 'PledgesItem',
  props: ['item', 'thekey'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      isSelected: false,
      isSection18: false
    }
  },
  mounted () {
    this.checkSection18A()
  },
  watch: {
    isSelected () {
      if (this.isSelected) {
        this.$emit('addSelected')
      } else {
        this.$emit('removeSelected')
      }
    }
  },
  computed: {
    ...mapGetters(['organisations', 'groups']),
    theOrganisation () {
      const org = this.organisations.find(organisation => organisation.organisationID === this.item.organisationID)
      return org.registeredName
    },
    theGroup () {
      if (this.groups && this.groups.length > 0) {
        const group = this.groups.find(group => group.donorGroupID === this.item.donorGroupID)
        if (group) {
          return group.donorGroupName
        } else {
          return null
        }
      } else {
        return null
      }
    },
    date () {
      const date = DateTime.fromMillis(parseInt(this.item.transactionDate))
      return date.toLocaleString(DateTime.DATE_FULL)
    },
    isOdd () {
      return this.thekey % 2
    }
  },
  methods: {
    ...mapActions(['check18A', 'downloadSection18']),
    async checkSection18A () {
      const status = await this.check18A(this.item.transactionID)
      this.isSection18 = status.canDownloadTaxCertificate
    },
    view () {
      this.$emit('showDetails')
    },
    downloadReceipt () {
      this.$emit('downloadReceipt')
    },
    async download18A () {
      await this.downloadSection18(this.item.transactionID)
    },
    printReceipt () {
      this.$emit('printReceipt')
    }
  }
}
</script>

<style scoped>
.dark_text {
  color: var(--normal-color-dark);
}
.position_absolute {
  position: absolute;
  left: -2rem;
}
.round {
  background-color: rgb(249, 250, 251);
  border-radius: 2rem;
}
.lightgrey_bg .round {
  background-color: #fff;
}
</style>
